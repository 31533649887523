import Vue from "vue";
import Vuex from "vuex";
import employee from "@/modules/employee";
import candidate from "@/modules/candidate";
import candidateList from "@/modules/candidateList";
import jobList from "@/modules/jobList";
import filter from "@/modules/filter";
import auth from "@/modules/auth";
import toaster from "@/modules/toaster";
import hospital from "@/modules/hospital";
import dashboard from "@/modules/dashboard";
import jobBoard from "@/modules/jobBoard";
import facility from "@/modules/facility";
import jobs from "@/modules/jobs";
import recruiterDashboard from "@/modules/recruiterDashboard";
import genericFilter from "@/modules/genericFilters/index";
import customer from "@/modules/customer";
import organisationDocuments from "@/modules/organisationDocuments";
import genericTabFilters from "@/modules/genericTabFilters/index";
import demoDOH from "@/modules/demoDOH";
import changePassword from "@/modules/changePassword";
import jobCandidateStatus from "@/modules/jobCandidateStatus";
import communication from "@/modules/communication";
import contactUser from "@/modules/contactUser";
import activityList from "@/modules/activityList";
import dynamicOrgUrl from "@/modules/dynamicOrgUrl";
import auditHistory from "@/modules/auditHistory";
import recruitingAgency from "@/modules/recruitingAgency";
import teamMembers from "@/modules/teamMembers";
import publicJobBoard from "@/modules/publicJobBoard";
import alert from "@/modules/alert";
import jobAllocation from "@/modules/jobAllocation";
import customerDocumentLib from "@/modules/customerDocumentLib";
import orgConfiguration from "@/modules/orgConfiguration";
import preScreening from "@/modules/preScreening";
import managementReport from "@/modules/managementReport";
import externalUsers from "@/modules/externalUsers";
import tfw from "@/modules/tfw";
import jobGroup from "@/modules/jobGroup"
import campaign from "@/modules/campaign";
import languageScore from "./modules/languageScore";
import course from "@/modules/course";
import ai from "@/modules/ai";
import webModule from "@/modules/webModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    matched: "rgb(105, 132, 238)",
    review: "rgb(229, 86, 102)",
    shortlist: "rgb(242, 183, 89)",
    inprocess: "rgb(148, 153, 35)",
    vault: "rgb(191, 115, 168)",
    applied: "#03A9F4",
    talentpool: "#af4bce",
    shortlist1: "#ea7369",
    shortlist2: "#29066B",
    submitted: "#a05195",
    interview: "rgb(149, 202, 87)",
    offered: "#488f31",
    onboarding: "rgb(125, 91, 212)",
    disapproved: "#dbc667",
    withdrawn: "#ff66cc",
    jobclosed: "#de425b",
    statusData: [
      { type: "Active", color: "#52c081", icon: "fa fa-check-circle" },
      { type: "Archive", color: "#f00", icon: "fa fa-check-circle" },
      { type: "Inactive", color: "#f00", icon: "fa fa-check-circle" },
    ],
    genderData: [
      {
        type: "Female",
        color: "#e16376",
        icon: "fa fa-check-circle",
        image: "img/female_avatar.png",
      },
      {
        type: "Male",
        color: "blue",
        icon: "fa fa-check-circle",
        image: "img/male_avatar.png",
      },
      {
        type: "Other",
        color: "black",
        icon: "fa fa-check-circle",
        image: "img/male_avatar.png",
      },
      {
        type: "Any",
        color: "black",
        icon: "fa fa-check-circle",
        image: "img/male_avatar.png",
      },
    ],
  },
  actions: {
    showLoader({ state, commit }) {
      commit("SET_LOADER", true);
    },
    hideLoader({ state, commit }) {
      commit("SET_LOADER", false);
    },
  },
  mutations: {
    ["SET_LOADER"](state, payload) {
      state.isLoading = payload;
    },
  },

  getters: {
    isLoading: (state) => state.isLoading,
    getColor: (state) => (key) => state[key],
    getStatusColor: (state) => (key) => {
      let data = state["statusData"].filter((val) => key === val.type);
      if (data.length) {
        return data[0]["color"];
      } else {
        return "#f00";
      }
    },
    getStatusIcon: (state) => (key) => {
      let data = state["statusData"].filter((val) => key === val.type);
      if (data.length) {
        return data[0]["icon"];
      } else {
        return "fa fa-check-circle";
      }
    },
    getGenderColor: (state) => (key) => {
      let result = state["genderData"].filter((val) => key === val.type);
      if (result.length) {
        return result[0]["color"];
      }
      return state["genderData"].find((val) => val.type == "Any").color;
    },
    getGenderImage: (state) => (key) => {
      let result = state["genderData"].filter((val) => key === val.type);
      if (result.length) {
        return result[0]["image"];
      }
      return state["genderData"].find((val) => val.type == "Any").image;
    },
  },
  modules: {
    employee,
    candidate,
    candidateList,
    jobList,
    filter,
    hospital,
    toaster,
    auth,
    dashboard,
    jobBoard,
    facility,
    jobs,
    genericFilter,
    customer,
    recruiterDashboard,
    organisationDocuments,
    genericTabFilters,
    demoDOH,
    changePassword,
    jobCandidateStatus,
    communication,
    contactUser,
    activityList,
    dynamicOrgUrl,
    auditHistory,
    recruitingAgency,
    teamMembers,
    publicJobBoard,
    alert,
    jobAllocation,
    customerDocumentLib,
    orgConfiguration,
    preScreening,
    managementReport,
    externalUsers,
    tfw,
    jobGroup,
    campaign,
    languageScore,
    course,
    ai,
    webModule
  },
});
