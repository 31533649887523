import axios from "axios";
import { BASE_URL, JGM_URL } from "@/service_urls";

const webService = {
  checkUserExistInCampaign: (payload) =>
    axios.post(
      `${JGM_URL}/jgm_endpoints/check_user_already_exist?email=${encodeURIComponent(
        payload.email
      )}&campaign_name=${payload.campaign_name}`
    ),
  campaignRegistration: (payload) =>
    axios.post(`${JGM_URL}/jgm_endpoints/campaign_registration`, payload),
  campaignRegisterLater: (payload) =>
    axios.post(`${JGM_URL}/jgm_endpoints/register_later?email=${payload.email}&campaign_name=${payload.campaign_name}`),
  checkCandidateExistInCampaignPreReg: (email) =>
    axios.get(
      `${BASE_URL}/campaign_pre_reg_candidates/check-candidate/${email}`
    ),
};
export default webService;
